<template>
  <v-app>
    <div v-if="$store.state.error.show">
      <ErrorDisplay/>
    </div>
    <div @retry="loadData" v-else-if="$store.state.firstLoad && !$store.state.loading && $store.state.chargingStations.length < 1">
      <NoStationsFound/>
    </div>
    <div v-else>
      <v-main style="padding: 0 !important; margin: 0 !important;">
        <LandingPage @start="loadData" v-if="!$store.state.showMap"/>
        <v-container fluid v-if="$store.state.showMap" style="padding: 0 !important; margin: 0 !important;">
          <v-row no-gutters style="padding: 0 !important; margin: 0 !important;">
            <v-col cols="3" v-if="$vuetify.display.mdAndUp" style="z-index: 10000000">
              <DesktopSideBarNav @refresh="loadData" :polylineRoute="polylineRoute" @navigationClicked="navigatePath" @navigationStarted="startNavigation" @navigationStopped="stopNavigation" @reRouteRequested="reRoutePath"/>
            </v-col>
            <v-col cols="12" md="9" style="height: 100vh; width: 100vw; position: relative;">
              <MapDisplay :polylineRoute="polylineRoute" @locationNavigated="navToCurrentLocation"/>
            </v-col>
          </v-row>
          <AppBar @refresh="loadData" v-if="!$vuetify.display.mdAndUp"/>
          <MobileStationNav v-if="$store.state.selectedStationId != null && $store.state.mapRoute.path" :polylineRoute="polylineRoute" @navigationClicked="navigatePath" @navigationStarted="startNavigation" @navigationStopped="stopNavigation" @reRouteRequested="reRoutePath"/>
          <MobileBottomSheet v-if="!$vuetify.display.mdAndUp" @navigationClicked="navigatePath"/>
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>
<style>
.v-fab.my-fab button{
  margin-right: 20px !important;
}
.my-list-scroll{
  height: calc(100vh - 80px);
  overflow-y: auto
}
* {
  scrollbar-width: thin;
  scrollbar-color: #1976D2 rgba(128, 128, 128, 0.3);
}
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: rgba(128, 128, 128, 0.3);
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #1976D2;
  border-radius: 20px;
}
</style>
<script>
import LandingPage from './components/LandingPage.vue'
import ErrorDisplay from './components/ErrorDisplay.vue'
import NoStationsFound from './components/NoStationsFound.vue'
import MapDisplay from './components/MapDisplay.vue'
import AppBar from './components/AppBar.vue'
import MobileStationNav from './components/MobileStationNav.vue'
import MobileBottomSheet from './components/MobileBottomSheet.vue'
import DesktopSideBarNav from './components/DesktopSideBarNav.vue'
import update from './mixins/update'
import optimisation from './mixins/optimisation'
import map from './mixins/map'
import api_request from './mixins/api_request'

export default ({
  name: 'App',
  components: {
    LandingPage,
    ErrorDisplay,
    NoStationsFound,
    DesktopSideBarNav,
    MapDisplay,
    AppBar,
    MobileBottomSheet,
    MobileStationNav
  },
  data () {
    return {

    }
  },
  watch: {
    '$store.state.navigationStarted'(v){
      this.setGeoLocation(v)
    },
    '$store.state.googleSourceLat'(v){
      if(this.$store.state.navigationStarted && !this.$store.state.mapDragged){
        this.setLiveMovement()
      }
    }
  },
  mixins: [update, optimisation, map, api_request],
  mounted() {
    this.setGeoLocation()
  },
  computed: {
    chunkedPath() {
      let path = [];
      if (this.selectedStationId !== null && this.mapRoute.path && this.mapRoute.path.length > 0) {
        const routePath = this.mapRoute.path;
        path.push(routePath[0]);
        for (let i = 1; i < routePath.length; i++) {
          const currentPoint = routePath[i];
          const prevPoint = routePath[i - 1];
          const divisionFactor = Math.round(prevPoint.dist / 5);

          if (divisionFactor > 1) {
            const chunks = this.divideLineIntoChunks(
                { x: prevPoint.lng, y: prevPoint.lat },
                { x: currentPoint.lng, y: currentPoint.lat },
                divisionFactor
            ).map((chunk) => ({
              lat: chunk.y,
              lng: chunk.x,
              time: Math.round(currentPoint.time / divisionFactor),
              dist: Math.round(currentPoint.dist / divisionFactor)
            }));
            path.push(...chunks.slice(1), {
              lat: currentPoint.lat,
              lng: currentPoint.lng,
              time: Math.round(currentPoint.time / divisionFactor),
              dist: Math.round(currentPoint.dist / divisionFactor)
            });
          } else {
            path.push(currentPoint);
          }
        }
      }
      return path;
    },
    polylineRoute() {
      let startIndex = 0;
      let minDist = Infinity;
      let totalDistance = 0;
      let totalTime = 0;
      let path = [];
      if(!this.$store.state.mapRoute.path){
        return {
          path,
          totalTime,
          totalDistance
        }
      }
      this.$store.state.mapRoute.path.forEach((p, i) => {
        if (this.$store.state.navigationStarted) {
          const dist = this.haversineDistanceInMeters(this.$store.state.googleSourceLat, this.$store.state.googleSourceLong, p.lat, p.lng);
          if (dist < minDist) {
            minDist = dist;
            startIndex = i;
            if (minDist === 0) return;
          }
        }
      });
      path = this.$store.state.mapRoute.path.slice(startIndex);
      path.forEach(p => {
        totalDistance += p.dist;
        totalTime += p.time;
      });
      return {
        path,
        totalDistance: totalDistance < 60 ? 60 : totalDistance,
        totalTime
      };
    }
  },
  methods: {
    async loadData(){
      try{
        let radius = this.$store.state.radius
        if(this.$store.state.googleSourceLong == null || this.$store.state.googleSourceLat == null){
          throw new Error("User's location could not be fetched")
        }
        let location = {
          lon: this.$store.state.googleSourceLong,
          lat: this.$store.state.googleSourceLat
        }
        this.clearData()
        const box = this.calculateBoundingBox(parseFloat(location.lat),parseFloat(location.lon),parseFloat(radius))
        await Promise.all([
          this.fetchRoadWorks(parseFloat(location.lon), parseFloat(location.lat), parseFloat(radius)),
          this.fetchTrafficSignals(parseFloat(location.lon), parseFloat(location.lat), parseFloat(radius)),
          this.getEVCSNearby(location.lon,location.lat,radius)
        ])
        await this.requestMapData({lat: box.maxLat, lon: box.minLon},{lat: box.minLat, lon: box.maxLon})

        this.optimiseRoute(true)
        this.$store.commit('setMultipleState',{
          showBottomSheet: true,
          loading: false,
          loadingText: '',
          showMap: true,
          osmNodes: {},
        })
        if(this.$store.state.chargingStations.length < 1){
          return;
        }
        while(!this.$store.state.mapRef){
          await new Promise(resolve => setTimeout(resolve, 500))
        }
        if(this.$store.state.googleSourceLat && this.$store.state.googleSourceLong && this.$store.state.mapRef){
          this.setMapCenter(this.$store.state.googleSourceLat,this.$store.state.googleSourceLong,200)
          this.setMapZoom(18,1000)
        }
      }catch(err){
        this.$store.commit('setState',{name: "error", value: {
            show: true,
            text: err.message,
            btn: [
              {
                text: 'Retry',
                color: 'orange',
                click: ()=>{
                  this.$store.commit('hideError')
                  this.loadData()
                }
              }
            ]
          }})
      }
    },
  }
})
</script>
