<template>
  <v-row>
    <v-col cols="12">
      <div class="text-body-1 mt-2 mb-1 font-weight-bold">
        Location Information
      </div>
      <v-divider/>
      <div class="text-body-2 my-1">
        <span class="mr-3 font-weight-bold">Address: </span>
        <span>{{station.address.freeformAddress}}</span>
      </div>
      <v-divider/>
      <div class="text-body-2 my-1">
        <span class="mr-3 font-weight-bold">Latitude: </span>
        <span>{{station.position.lat}}</span>
      </div>
      <v-divider/>
      <div class="text-body-2 my-1">
        <span class="mr-3 font-weight-bold">Longitude: </span>
        <span>{{station.position.lon}}</span>
      </div>
      <v-divider/>
      <div v-if="station.url">
        <div class="text-body-2 my-1">
          <span class="mr-3 font-weight-bold">Website: </span>
          <a :href="station.url" target="_blank">{{station.url}}</a>
        </div>
        <v-divider/>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="text-body-1 mt-2 mb-1 font-weight-bold">
        Available Connectors
      </div>
      <v-divider/>
      <div class="mb-6" v-for="c in station.connectorAvailability">
        <div class="text-body-2 my-1">
          <span class="mr-3 font-weight-bold">Type: </span>
          <span>{{c.type}}</span>
        </div>
        <v-divider/>
        <div class="text-body-2 my-1">
          <span class="mr-3 font-weight-bold">Available slots: </span>
          <span>{{c.availability.current.available}}</span>
        </div>
        <v-divider/>
        <div class="text-body-2 my-1">
          <span class="mr-3 font-weight-bold">Rated Power: </span>
          <span>{{station.connectors.find(co=>co.connectorType === c.type).ratedPowerKW}}KW</span>
        </div>
        <v-divider/>
        <div class="text-body-2 my-1">
          <span class="mr-3 font-weight-bold">Voltage: </span>
          <span>{{station.connectors.find(co=>co.connectorType === c.type).voltageV}}V</span>
        </div>
        <v-divider/>
        <div class="text-body-2 my-1">
          <span class="mr-3 font-weight-bold">Current: </span>
          <span>{{station.connectors.find(co=>co.connectorType === c.type).currentA}}A</span>
        </div>
        <v-divider/>
        <div class="text-body-2 my-1">
          <span class="mr-3 font-weight-bold">Current Type: </span>
          <span>{{station.connectors.find(co=>co.connectorType === c.type).currentType}}</span>
        </div>
        <v-divider/>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "StationInformation",
  computed: {
    station(){
      return this.$store.getters.selectedChargingStation
    }
  }
}
</script>

<style scoped>

</style>