<template>
  <div>
    <v-card v-if="$store.state.selectedStationId != null && $store.state.mapRoute.path" :elevation="3" rounded :height="$store.state.showNavDetails ? 150 : 60" class="pa-3 d-md-none" style="position: fixed; top: 0; width: 100%; border-radius: 0 0 20px 20px;">
      <v-card-actions class="my-0 py-0" style="min-height: 0">
        <v-btn v-if="!$store.state.navigationStarted" @click="backToCSList" icon="mdi-arrow-left" height="30" class="mt-2" :color="$store.state.baseColor" variant="plain" :ripple="false"></v-btn>
        <v-spacer v-if="!$store.state.navigationStarted"></v-spacer>
        <div v-if="!$store.state.showNavDetails" class="font-weight-bold">
          <span class="text-h6" style="color: #1976D2;">{{parseFloat(polylineRoute.totalDistance/1000).toFixed(1)}}KM</span>
          <span class="ml-2 text-subtitle-1 font-weight-bold" style="color: #1976D2;">~ {{parseInt(polylineRoute.totalTime/60)}}min</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn size="x-large" @click="$store.commit('setState',{name: 'showNavDetails', value: !$store.state.showNavDetails})" :icon="$store.state.showNavDetails ? 'mdi-menu-up' : 'mdi-menu-down'" height="30" class="mt-2" :color="$store.state.baseColor" variant="plain" :ripple="false"></v-btn>
      </v-card-actions>
      <v-card-title class="text-h6 font-weight-bold">{{ $store.getters.selectedChargingStation.address.streetName + ', ' + $store.getters.selectedChargingStation.address.municipality }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <div class="font-weight-bold">
              <span class="text-h6" style="color: #1976D2;">{{parseFloat(polylineRoute.totalDistance/1000).toFixed(1)}}KM</span>
              <span class="ml-2 text-subtitle-1 font-weight-bold" style="color: #1976D2;">~ {{parseInt(polylineRoute.totalTime/60)}}min</span>
            </div>
          </v-col>
          <v-col cols="6" class="d-flex">
            <v-btn @click="startNavigation" v-if="!$store.state.navigationStarted" size="small" variant="plain" :color="$store.state.baseColor" style="text-decoration: none">Start</v-btn>
            <v-btn @click="stopNavigation" v-if="$store.state.navigationStarted" size="small" variant="plain" :color="$store.state.baseColor" style="text-decoration: none">Stop</v-btn>
            <v-btn :loading="$store.state.rerouting" @click="reRoutePath" v-if="showReRoute" size="small" variant="plain" :color="$store.state.baseColor" style="text-decoration: none">Re-route</v-btn>
            <v-spacer/>
            <v-btn v-if="!$store.state.showMobileStationNav" @click="$store.commit('setState',{name: 'showMobileStationNav', value: true})" size="small" variant="plain" :color="$store.state.baseColor" style="text-decoration: none">More</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-bottom-sheet v-model="$store.state.showMobileStationNav" style="width: 100vw; height: 85vh;">
      <v-card
          class="pt-5 elevation-10"
          style="height: 100vh; width: 100vw; border-radius: 30px 30px 0 0 !important;"
      >
        <v-card-actions class="my-0 py-0 mx-3" style="min-height: 0">
          <v-spacer></v-spacer>
          <v-btn variant="plain" :color="$store.state.baseColor" @click="$store.commit('setState',{name: 'showMobileStationNav', value: !$store.state.showMobileStationNav})">
            <v-icon size="x-large">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="pa-5 my-list-scroll pb-16">
          <StationInformation/>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import StationInformation from "@/components/StationInformation";
export default {
  name: "MobileStationNav",
  components: {StationInformation},
  props: {
    polylineRoute: Object
  },
  computed: {
    showReRoute(){
      return this.$store.state.navigationStarted &&
          this.polylineRoute.path.length > 0 &&
          this.haversineDistanceInMeters(this.$store.state.googleSourceLat, this.$store.state.googleSourceLong,
              this.polylineRoute.path[0].lat, this.polylineRoute.path[0].lng) > 100
    }
  },
  methods: {
    haversineDistanceInMeters(lat1, lon1, lat2, lon2) {
      const R = 6371000; // Radius of the Earth in meters
      const dLat = (lat2 - lat1) * Math.PI / 180;
      const dLon = (lon2 - lon1) * Math.PI / 180;

      const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    },
    backToCSList(){
      this.$store.commit("setMultipleState",{
        selectedStationId: null,
        showNavDetails: false,
        showBottomSheet: true,
        mapDragged: false,
        showMobileStationNav: false
      })
    },
    startNavigation(){
      this.$emit('navigationStarted');
    },
    stopNavigation(){
      this.$emit('navigationStopped');
    },
    reRoutePath(){
      this.$emit('reRouteRequested');
    },
  }
}
</script>

<style scoped>

</style>