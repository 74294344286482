import { createStore } from 'vuex'
import { Graph } from "@/models/graph"

export default createStore({
    state () {
        return {
            radius: 3,
            googleSourceLat: 53.51197,//53.548842,//53.51197,
            googleSourceLong: -2.2053775,//-2.124504,//-2.2053775,
            googleMapApi: process.env.VUE_APP_GOOGLE_MAP_API,
            tomTomApi: process.env.VUE_APP_TOMTOM_API,
            roadGraph: new Graph(),
            showMap: false,
            showBottomSheet: false,
            chargingStations: [],
            validHighwayClasses: {
                motorway: {
                    maxspeed: 70,
                    give_and_take_factor: 0.5,
                    traffic_factor: 1.7
                },
                trunk: {
                    maxspeed: 70,
                    give_and_take_factor: 0.5,
                    traffic_factor: 1.7
                },
                primary: {
                    maxspeed: 60,
                    give_and_take_factor: 0.7,
                    traffic_factor: 1.4
                },
                secondary: {
                    maxspeed: 60,
                    give_and_take_factor: 0.8,
                    traffic_factor: 1.3
                },
                tertiary: {
                    maxspeed: 60,
                    give_and_take_factor: 0.9,
                    traffic_factor: 1.2
                },
                unclassified: {
                    maxspeed: 40,
                    give_and_take_factor: 1.1,
                    traffic_factor: 0.9
                },
                residential: {
                    maxspeed: 30,
                    give_and_take_factor: 1.5,
                    traffic_factor: 0.5
                },
                motorway_link: {
                    maxspeed: 30,
                    give_and_take_factor: 1.2,
                    traffic_factor: 0.8
                },
                trunk_link: {
                    maxspeed: 30,
                    give_and_take_factor: 1.2,
                    traffic_factor: 0.8
                },
                primary_link: {
                    maxspeed: 25,
                    give_and_take_factor: 1.3,
                    traffic_factor: 0.7
                },
                secondary_link: {
                    maxspeed: 25,
                    give_and_take_factor: 1.5,
                    traffic_factor: 0.5
                },
                tertiary_link: {
                    maxspeed: 25,
                    give_and_take_factor: 1.7,
                    traffic_factor: 0.3
                },
                unclassified_link: {
                    maxspeed: 20,
                    give_and_take_factor: 1.7,
                    traffic_factor: 0.3
                },
                residential_link: {
                    maxspeed: 15,
                    give_and_take_factor: 1.7,
                    traffic_factor: 0.3
                },
                mini_roundabout: {
                    maxspeed: 15,
                    give_and_take_factor: 1.8,
                    traffic_factor: 0.2
                },
                living_street: {
                    maxspeed: 20,
                    give_and_take_factor: 1.5,
                    traffic_factor: 0.5
                },
                service: {
                    maxspeed: 15,
                    give_and_take_factor: 2,
                    traffic_factor: 1
                },
                traffic_signals: {
                    maxspeed: 20,
                    give_and_take_factor: 2,
                    traffic_factor: 0
                }
            },
            osmNodes: {},
            osmWays: {},
            roadWorks: [],
            trafficSignals: [],
            userSpeedFactor: 0.6,
            trafficWaitTime: 20,
            giveAndTakeWaitTime: 20,
            trafficTypes: ['road_closure', 'lane_closure', 'give_and_take'],
            loading: false,
            loadingText: '',
            selectedStationId: null,
            navigationStarted: false,
            baseColor: "#1976D2",
            showNavDetails: false,
            mapDragged: false,
            geoLocationID: null,
            rerouting: false,
            mapRoute: {},
            error: {
                show: false,
                text: '',
                btn: []
            },
            hasArrived: false,
            firstLoad: false,
            inAccessibleHighway: ['private', 'no', 'bus'],
            mapRef: undefined,
            showMobileStationNav: false
        }
    },
    getters: {
        sortedChargingStations(state){
            return [...state.chargingStations.filter(cs=>cs.route)].sort((a, b) => a.route.time - b.route.time);
        },
        selectedChargingStation(state){
            return state.chargingStations.find(cs=>cs.id===state.selectedStationId)
        },
    },
    mutations: {
        setState (state, payload) {
            state[payload.name] = payload.value
        },
        setMultipleState(state, payload){
            for(const key in payload){
                state[key] = payload[key]
            }
        },
        toggleRoadWorkWindow(state,index){
            state.roadWorks[index].showMarkerWindow = !state.roadWorks[index].showMarkerWindow
        },
        toggleStationWindow(state,index){
            state.chargingStations[index].showMarkerWindow = !state.chargingStations[index].showMarkerWindow
        },
        toggleChargingStationConnector(state,id){
            let index = state.chargingStations.findIndex(s=>s.id===id)
            if(index > -1){
                state.chargingStations[index].showConnectors = !state.chargingStations[index].showConnectors
            }
        },
        setStationWindow(state,payload){
            state.chargingStations[payload.index].showMarkerWindow = payload.value
        },
        setRoadWorkWindow(state,payload){
            state.roadWorks[payload.index].showMarkerWindow = payload.value
        },
        setChargingStationRoute(state, payload){
            state.chargingStations[payload.index]['route'] = payload.route
        },
        setOSMNode(state, payload){
            state.osmNodes[payload.id] = payload
        },
        setOSMWay(state, payload){
            state.osmWays[payload.id] = payload
        },
        setStations(state, payload){
            let index = state.chargingStations.findIndex(s=>s.id===payload.id)
            if(index > -1){
                state.chargingStations[index] = payload
            }
        },
        hideError(state){
            state.error.show = false
        },
        changeTomTomApiKey(state){
            state.tomTomApi = process.env.VUE_APP_TOMTOM_API_2
        }
    }
})