<template>
  <v-main style="padding: 0 !important; margin: 0 !important;">
    <v-sheet style="height: 100vh; width: 100vw; padding: 0 !important; margin: 0 !important;" class="d-flex align-center">
      <div class="mx-auto text-center">
        <div class="mx-auto">
          <v-icon style="font-size: 80px;" color="red">mdi-alert-circle</v-icon>
        </div>
        <div class="text-subtitle-2 text-md-subtitle-1 font-weight-bold mb-2" style="line-height: 1.3">{{$store.state.error.text}}</div>
        <v-btn variant="flat" class="mx-1 text-white" v-for="(b, bi) in $store.state.error.btn" :key="'btn_'+bi" :color="b.color" theme="dark" @click="b.click">{{b.text}}</v-btn>
      </div>
    </v-sheet>
  </v-main>
</template>

<script>
export default {
  name: "ErrorDisplay"
};
</script>
