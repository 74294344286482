<template>
  <div class="mx-auto text-center">
    <div class="my-loader mx-auto mb-3"></div>
    <div class="text-subtitle-1 font-weight-bold">{{loadingText}}</div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loadingText: String
  }
}
</script>

<style>
  .my-loader {
    width: 40px;
    aspect-ratio: 1;
    position: relative;
    transform: rotate(45deg);
  }
  .my-loader:before,
  .my-loader:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50% 50% 0 50%;
    background: #1976D2;
    -webkit-mask: radial-gradient(circle 10px at 50% 50%, #0000 94%, #000);
  }
  .my-loader:after {
    animation: l6 1s infinite;
    transform: perspective(300px) translateZ(0px)
  }
  @keyframes l6 {
    to {transform: perspective(300px) translateZ(150px);opacity:0}
  }
</style>