<template>
<div>
  <v-card
      v-if="$store.state.selectedStationId == null"
      class="mx-auto pt-6 rounded-lg elevation-10"
      style="height: 100vh; padding-bottom: 100px;"
  >
    <v-card-title class="pb-6">
      <div class="text-right">
        <v-btn variant="outlined" size="small" :color="$store.state.baseColor" @click="loadData">
          Refresh
        </v-btn>
      </div>
      <p class="text-subtitle-1 font-weight-bold">Choose from the nearest stations</p>
    </v-card-title>
    <v-card-text class="pa-5 my-list-scroll" style="">
      <StationList @stationSelected="navigatePath"/>
    </v-card-text>
  </v-card>
  <v-card v-if="$store.state.selectedStationId != null && $store.state.mapRoute.path" class="mx-auto pt-6 rounded-lg elevation-10"
          style="height: 100vh; padding-bottom: 100px;">
    <v-card-actions class="my-0 py-0" style="min-height: 0">
      <v-btn v-if="!$store.state.navigationStarted" @click="backToCSList" icon="mdi-arrow-left" height="30" class="mt-2" :color="$store.state.baseColor" variant="plain" :ripple="false"></v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-card-title class="text-h6 font-weight-bold">{{ $store.getters.selectedChargingStation.address.streetName + ', ' + $store.getters.selectedChargingStation.address.municipality }}</v-card-title>
    <v-card-text class="my-list-scroll" style="padding-bottom: 80px;">
      <v-row>
        <v-col cols="12" class="mb-0 pb-0">
          <div class="font-weight-bold text-center">
            <span class="text-h6" style="color: #1976D2;">{{parseFloat(polylineRoute.totalDistance/1000).toFixed(1)}}KM</span>
            <span class="ml-2 text-subtitle-1 font-weight-bold" style="color: #1976D2;">~ {{parseInt(polylineRoute.totalTime/60)}}min</span>
          </div>
        </v-col>
        <v-col cols="12" class="text-right mb-0 pb-0">
          <v-btn @click="startNavigation" v-if="!$store.state.navigationStarted" variant="plain" :color="$store.state.baseColor" style="text-decoration: none">Start</v-btn>
          <v-btn @click="stopNavigation" v-if="$store.state.navigationStarted" variant="plain" :color="$store.state.baseColor" style="text-decoration: none">Stop</v-btn>
          <v-btn class="ml-2" :loading="$store.state.rerouting" @click="reRoutePath" v-if="showReRoute" variant="outlined" :color="$store.state.baseColor" style="text-decoration: none">Re-route</v-btn>
        </v-col>
        <v-col cols="12">
          <StationInformation/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import optimisation from '../mixins/optimisation'
import StationList from './StationList.vue'
import StationInformation from "@/components/StationInformation";
export default {
  name: "DesktopSideBarNav",
  components: {
    StationInformation,
    StationList
  },
  props: {
    polylineRoute: Object
  },
  computed: {
    showReRoute(){
     return this.$store.state.navigationStarted &&
         this.polylineRoute.path.length > 0 &&
         this.haversineDistanceInMeters(this.$store.state.googleSourceLat, this.$store.state.googleSourceLong,
             this.polylineRoute.path[0].lat, this.polylineRoute.path[0].lng) > 100
    }
  },
  mixins: [optimisation],
  methods: {
    haversineDistanceInMeters(lat1, lon1, lat2, lon2) {
      const R = 6371000; // Radius of the Earth in meters
      const dLat = (lat2 - lat1) * Math.PI / 180;
      const dLon = (lon2 - lon1) * Math.PI / 180;

      const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    },
    navigatePath(id) {
      this.$emit('navigationClicked', id);
    },
    backToCSList(){
      this.$store.commit("setMultipleState",{
        selectedStationId: null,
        showNavDetails: false,
        showBottomSheet: true,
        mapDragged: false,
        showMobileStationNav: false
      })
    },
    startNavigation(){
      this.$emit('navigationStarted');
    },
    stopNavigation(){
      this.$emit('navigationStopped');
    },
    reRoutePath(){
      this.$emit('reRouteRequested');
    },
    loadData(){
      this.$emit("refresh")
    },
  }
}
</script>

<style scoped>

</style>