export class GraphNode {
    constructor(id, data) {
        this.id = id;
        this.data = data;
        this.edges = {};
    }

    addEdge(node, data) {
        this.edges[node.id] = data
    }

    removeEdge(node) {
        delete(this.edges[node.id])
    }

    getEdges() {
        return Object.keys(this.edges).map(e=>{
            return this.edges[e];
        })
    }
}
