<template>
  <v-card class="mb-3" v-for="(cs, csi) in $store.getters.sortedChargingStations" :key="cs.id" variant="flat" hover>
    <v-card-title class="text-overline pa-5 mb-0 pb-0">
      <div class="text-right font-weight-bold">
        <span class="text-subtitle-1" style="color: #1976D2;">{{cs.route ? parseFloat(cs.route.distance/1000).toFixed(1) : '--'}}KM</span>
        <span v-if="cs.route" class="ml-2 text-subtitle-2" style="color: #1976D2;">~ {{parseInt(cs.route.time/60)}}min</span>
      </div>
      <span class="text-subtitle-1 font-weight-bold">{{cs.name}}</span>
    </v-card-title>
    <v-card-subtitle class="text-subtitle-2 mb-2">{{cs.address.streetName + ', ' + cs.address.municipality}}</v-card-subtitle>
    <v-card-actions class="my-0 py-0 mb-2" style="min-height: 0">
      <v-btn size="small" variant="plain" :color="$store.state.baseColor" @click="$store.commit('toggleChargingStationConnector',cs.id)">Connectors</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="cs.url !== undefined && cs.url != null" size="small" variant="plain" :color="$store.state.baseColor" :href="cs.url" target="_blank">Visit Website</v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="cs.showConnectors">
        <v-divider></v-divider>
        <v-card-text>
          <v-list lines="one">
            <div v-for="(c, ci) in cs.connectorAvailability" :key="'ca_'+ci">
              <v-list-item :title="c.type" :subtitle="(c.availability.current.available > 0 ? c.availability.current.available : 'No') + ' slot'+(c.availability.current.available > 1 ? 's' : '')+' available'">

              </v-list-item>
              <v-divider v-if="ci < cs.connectorAvailability.length - 1"/>
            </div>
          </v-list>
        </v-card-text>
      </div>
    </v-expand-transition>
    <v-divider></v-divider>
    <v-list-item v-if="cs.route" @click="selectStation(cs.id)" class="font-weight-bold text-blue" append-icon="mdi-arrow-right" :color="$store.state.baseColor" :base-color="$store.state.baseColor" subtitle="Select Station" link></v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "StationList",
  props: {

  },
  mounted(){
    //console.log(this.$store.state.chargingStations)
  },
  methods: {
    selectStation(id) {
      this.$emit('stationSelected', id);
    }
  }
};
</script>
