<template>
  <div>
    <GoogleMap
        ref="mapRef"
        :api-key="$store.state.googleMapApi"
        style="width: 100%; position: absolute;"
        :style="{height: mapHeight, top: mapTop}"
        mapTypeId="roadmap"
        :streetViewControl="false"
        gestureHandling="greedy"
        :zoomControl="false"
        :mapTypeControl="false"
        :mapId="mapId"
        @drag="$store.commit('setState',{name: 'mapDragged', value: true})"
    >
      <CustomMarker :options="{position: {lat: parseFloat($store.state.googleSourceLat), lng: parseFloat($store.state.googleSourceLong)}}">
        <div style="text-align: center">
          <v-avatar color="blue-darken-2" class="text-center" size="30">
            <v-icon icon="mdi-car-electric" size="small"></v-icon>
          </v-avatar>
          <!--              <img width="30" height="30" src="car.png" alt="car-top-view"/>-->
        </div>
      </CustomMarker>
      <Polyline v-if="$store.state.selectedStationId != null && $store.state.mapRoute.path" :options="{
                    geodesic: true,
                    strokeColor: '#1976D2',
                    strokeOpacity: 1.0,
                    strokeWeight: 8,
                    path: polylineRoute.path
                  }" />
      <CustomMarker @click="$store.commit('toggleStationWindow',csi)" v-for="(cs, csi) in $store.state.chargingStations" :key="'cs_'+cs.id" :options="{position: {lat: cs.position.lat, lng: cs.position.lon }}">
        <div style="text-align: center">
          <v-avatar color="green-darken-2" class="text-center" size="40">
            <v-icon icon="mdi-ev-station" size="large"></v-icon>
          </v-avatar>
          <img v-if="false" width="30" height="30" src="evcs.png" alt="park-and-charge"/>
        </div>
      </CustomMarker>
      <CustomMarker @click="$store.commit('toggleRoadWorkWindow',wi)" v-for="(w,wi) in $store.state.roadWorks.filter(rw=>rw.object_data.traffic_management_type_ref === 'road_closure' || rw.object_data.traffic_management_type_ref === 'lane_closure')" :key="'works_'+w.object_data.work_reference_number" :options="{position: {lat: w.object_data.works_location_coordinates.lat === undefined ? w.object_data.works_location_coordinates[0].lat : w.object_data.works_location_coordinates.lat, lng: w.object_data.works_location_coordinates.lon === undefined ? w.object_data.works_location_coordinates[0].lon : w.object_data.works_location_coordinates.lon }}">
        <div style="text-align: center">
          <img width="30" height="30" src="works.png" alt="under-construction"/>
        </div>
      </CustomMarker>
      <InfoWindow v-for="(w,wi) in $store.state.roadWorks.filter(w=>(w.traffic_type === 'road_closure' || w.traffic_type === 'lane_closure') && w.showMarkerWindow)" @closeclick="()=>{this.$store.commit('setRoadWorkWindow',{index: wi, value: false})}" :key="'works_i_'+w.object_data.work_reference_number" :options="{position: {lat: w.object_data.works_location_coordinates.lat === undefined ? w.object_data.works_location_coordinates[0].lat : w.object_data.works_location_coordinates.lat, lng: w.object_data.works_location_coordinates.lon === undefined ? w.object_data.works_location_coordinates[0].lon : w.object_data.works_location_coordinates.lon }}">
        <div>
          Ongoing: {{w.object_data.traffic_management_type}}
        </div>
      </InfoWindow>
      <InfoWindow v-for="(cs, csi) in $store.state.chargingStations.filter(s=>s.showMarkerWindow)" @closeclick="()=>{this.$store.commit('setStationWindow',{index: csi, value: false})}" :key="'cs_i_'+cs.id" :options="{position: {lat: cs.position.lat, lng: cs.position.lon }}">
        <div v-html="`<span class='font-weight-bold'>${cs.name}</span> <br/> ${cs.address.freeformAddress}`">

        </div>
      </InfoWindow>

    </GoogleMap>
    <v-fab
        class="mb-8 my-fab"
        icon="mdi-navigation"
        location="bottom right"
        size="small"
        app
        :color="$store.state.baseColor"
        @click="navToCurrentLocation"
    ></v-fab>
    <v-dialog v-model="$store.state.hasArrived" width="auto">
      <v-card
          class="pa-6 text-center rounded-lg elevation-15"
          max-width="350"
      >
        <v-card-text>
          <v-avatar :color="$store.state.baseColor" class="text-center" size="60">
            <v-icon icon="mdi-ev-station" size="x-large"></v-icon>
          </v-avatar>
          <div class="text-body my-4">
            You have reach the selected charging station. Charge Up!
          </div>
          <v-btn @click="$store.commit('setState',{name: 'hasArrived', value: false})" variant="flat" :color="$store.state.baseColor" block rounded>
            Understood
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GoogleMap, Polyline, CustomMarker, InfoWindow, Marker } from 'vue3-google-map';

export default {
  name: "MapDisplay",
  components: {
    GoogleMap,
    Polyline,
    CustomMarker,
    InfoWindow,
    Marker
  },
  props: {
    polylineRoute: Object
  },
  watch:{

  },
  mounted(){
    this.$store.commit('setState',{
      name: 'mapRef',
      value: this.$refs.mapRef
    })
  },
  computed: {
    mapId(){
      return process.env.VUE_APP_GOOGLE_MAP_ID
    },
    mapHeight() {
      return this.$vuetify.display.mdAndUp || this.$store.state.showBottomSheet ? '100vh' : (this.$store.state.showNavDetails ? 'calc(100vh - 150px)' : 'calc(100vh - 50px)');
    },
    mapTop() {
      return this.$vuetify.display.mdAndUp || this.$store.state.showBottomSheet ? '0' : (this.$store.state.showNavDetails ? '150px' : '50px');
    }
  },
  methods: {
    navToCurrentLocation() {
      this.$emit('locationNavigated');
    }
  }
};
</script>
