<template>
  <v-sheet style="height: 100vh; width: 100vw; padding: 0 !important; margin: 0 !important;" class="d-flex align-center">
    <v-card
        v-if="!$store.state.loading"
        class="mx-auto pa-6 text-center rounded-lg elevation-15"
        width="350"
    >
      <v-card-text>
        <v-avatar :color="$store.state.baseColor" class="text-center" size="60">
          <v-icon icon="mdi-car-electric" size="x-large"></v-icon>
        </v-avatar>
        <div class="text-body my-4">
          Navigate the electric landscape with ease. Discover charging stations near you and plan your trips.
        </div>
        <v-btn @click="startLoading" variant="flat" append-icon="mdi-arrow-right" :color="$store.state.baseColor" block rounded>
          Locate
        </v-btn>
      </v-card-text>
    </v-card>
    <Loader v-if="$store.state.loading" :loadingText="$store.state.loadingText"/>
  </v-sheet>
</template>

<script>
import Loader from './Loader.vue'
export default {
  name: "LandingPage",
  components: {
    Loader
  },
  methods: {
    startLoading() {
      this.$emit('start');
    },
  }
}
</script>

<style scoped>

</style>