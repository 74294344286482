export default {
    methods: {
        toRadians(degrees) {
            return degrees * (Math.PI / 180);
        },
        toDegrees(radians) {
            return radians * (180 / Math.PI);
        },
        calculateBearing(lat1, lon1, lat2, lon2) {
            lat1 = this.toRadians(lat1);
            lon1 = this.toRadians(lon1);
            lat2 = this.toRadians(lat2);
            lon2 = this.toRadians(lon2);
            let dLon = lon2 - lon1;
            let y = Math.sin(dLon) * Math.cos(lat2);
            let x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
            let bearing = Math.atan2(y, x);
            bearing = this.toDegrees(bearing);
            bearing = (bearing + 360) % 360;
            return bearing;
        },
        navigatePath(cs_id){
            this.$store.commit("setState",{
                name: 'selectedStationId',
                value: cs_id
            })
            this.$store.commit("setMultipleState",{
                showBottomSheet: false,
                showNavDetails: true,
                mapRoute: {
                    ...this.$store.getters.selectedChargingStation.route
                }
            })
            let path = this.$store.state.mapRoute.path
            let minLon = Infinity, minLat = Infinity, maxLon = -Infinity, maxLat = -Infinity
            path.forEach(p=>{
                minLon = p.lng < minLon ? p.lng : minLon
                maxLon = p.lng > maxLon ? p.lng : maxLon
                minLat = p.lat < minLat ? p.lat : minLat
                maxLat = p.lat > maxLat ? p.lat : maxLat
            })
            let bearing = this.calculateBearing(path[0].lat,path[0].lng,path[path.length - 1].lat,path[path.length - 1].lng)
            this.setFitBounds({lng: minLon, lat: maxLat}, {lng: maxLon, lat: minLat},500)
            this.setMapHeading(bearing, 500)
        },
        refreshRoute(){
            this.$store.commit("setMultipleState",{
                showMap: false,
                loading: true
            })
            this.optimiseRoute()
            this.$store.commit("setMultipleState",{
                showMap: true,
                loading: false,
                loadingText: 'Please wait while we navigate your recharge journey'
            })
        },
        startNavigation(){
            this.$store.commit("setMultipleState",{
                navigationStarted: true,
                mapDragged: false,
                showNavDetails: false
            })
            this.setLiveMovement()
            //this.navToCurrentLocation()
        },
        stopNavigation(){
            this.$store.commit("setState",{name: "navigationStarted", value: false})
            this.moveCamera(this.$store.state.googleSourceLat, this.$store.state.googleSourceLong,0,18,0,200)
        },
        createGrids(topLeft, bottomRight, numCols, numRows){
            const grids = [];
            const latStep = (topLeft.lat - bottomRight.lat) / numRows;
            const lngStep = (bottomRight.lon - topLeft.lon) / numCols;

            for (let i = 0; i < numRows; i++) {
                for (let j = 0; j < numCols; j++) {
                    const cellTopLeft = {
                        lat: topLeft.lat - i * latStep,
                        lon: topLeft.lon + j * lngStep
                    };
                    const cellBottomRight = {
                        lat: cellTopLeft.lat - latStep,
                        lon: cellTopLeft.lon + lngStep
                    };
                    grids.push({
                        topLeft: cellTopLeft,
                        bottomRight: cellBottomRight
                    });
                }
            }

            return grids;
        },
        calculateBoundingBox(lat, lon, radius) {
            const earthRadius = 6371;
            return {
                minLat: lat - (radius / earthRadius) * (180 / Math.PI),
                maxLat: lat + (radius / earthRadius) * (180 / Math.PI),
                minLon: lon - (radius / earthRadius) * (180 / Math.PI) / Math.cos(lat * Math.PI / 180),
                maxLon: lon + (radius / earthRadius) * (180 / Math.PI) / Math.cos(lat * Math.PI / 180)
            };
        },
        setMapCenter(lat, lon, delay = 0){
            setTimeout(()=>{
                this.$store.state.mapRef.map.setCenter(new this.$store.state.mapRef.api.LatLng(parseFloat(lat), parseFloat(lon)))
            }, delay)
        },
        setMapZoom(level, delay = 0){
            setTimeout(()=>{
                this.$store.state.mapRef.map.setZoom(level)
            }, delay)
        },
        setMapHeading(degree, delay = 0){
            setTimeout(()=>{
                this.$store.state.mapRef.map.setHeading(degree)
            }, delay)
        },
        setMapTilt(degree, delay = 0){
            setTimeout(()=>{
                this.$store.state.mapRef.map.setTilt(degree)
            }, delay)
        },
        setFitBounds(pos1, pos2,delay = 0){
            setTimeout(()=>{
                let bounds = new this.$store.state.mapRef.api.LatLngBounds()
                bounds.extend(pos1)
                bounds.extend(pos2)
                this.$store.state.mapRef.map.fitBounds(bounds, this.$vuetify.display.mdAndUp ? 200 : 75)
            },delay)
        },
        navToCurrentLocation(){
            this.$store.commit('setState',{
                mapDragged: false
            })
            this.moveCamera(this.$store.state.googleSourceLat, this.$store.state.googleSourceLong,this.$store.state.mapRef.map.getHeading(),18,this.$store.state.navigationStarted ? 45 : 0,300)
        },
        moveCamera(lat,lon,heading = 0,zoom = 18,tilt = 0,delay = 0){
            setTimeout(()=>{
                this.$store.state.mapRef.map.moveCamera({
                    center: new this.$store.state.mapRef.api.LatLng(lat, lon),
                    zoom,
                    heading,
                    tilt
                })
            },delay)
        },
        setGeoLocation(live = false){
            if (!navigator.geolocation) {
                this.error = {
                    show: true,
                    text: 'This device does not support geolocation, please try using a different browser.',
                    btn: []
                }
                return
            }
            try{
                navigator.geolocation.clearWatch(this.$store.state.geoLocationID)
            }catch(err){

            }
            let id = navigator.geolocation.watchPosition(pos=>{
                this.$store.commit('setMultipleState',{
                    googleSourceLong: pos.coords.longitude,
                    googleSourceLat: pos.coords.latitude
                })
            }, error=>{
                switch(error.code) {
                    case error.PERMISSION_DENIED:
                        if(window.location.protocol.indexOf('https') > 0){
                            this.$store.commit('setState',{
                                name: "error",
                                value: {
                                    show: true,
                                    text: 'Please enable location sharing for this application.',
                                    btn: [
                                        {
                                            text: 'Retry',
                                            color: 'orange',
                                            click: ()=>{
                                                this.$store.commit('hideError')
                                                this.setGeoLocation()
                                            }
                                        }
                                    ]
                                }
                            })
                        }
                        break;
                    case error.POSITION_UNAVAILABLE:
                        console.error("Location information is unavailable.")
                        break;
                    case error.TIMEOUT:
                        console.error("The request to get user location timed out.")
                        break;
                    case error.UNKNOWN_ERROR:
                        console.error("An unknown error occurred.")
                        break;
                }
            },{
                enableHighAccuracy: live,
                timeout: live ? 200 : 5000,
                maximumAge: 0
            });
            this.$store.commit('setState',{
                name: "geoLocationID",
                value: id
            })
        },
        setLiveMovement(){
            if(!this.polylineRoute.path){
                return
            }
            let lat = this.$store.state.googleSourceLat
            let lng = this.$store.state.googleSourceLong
            let path = this.polylineRoute.path
            if(path.length < 2 && this.$store.state.mapRoute.path.length > 2
                && this.haversineDistanceInMeters(lat,lng,
                    path[0].lat,path[0].lng) < 5){
                this.$store.commit('setMultipleState',{
                    hasArrived: true,
                    navigationStarted: false
                })
            }
            let bearing;

            if(path.length > 5){
                bearing = this.calculateBearing(lat,lng,path[5].lat,path[5].lng)
                this.setFitBounds({lat: lat, lng: lng}, path[5])
                this.setMapHeading(bearing)
                this.setMapTilt(45)
            }else{
                bearing = this.calculateBearing(lat,lng,this.$store.getters.selectedChargingStation.position.lat,this.$store.getters.selectedChargingStation.position.lon)
                this.moveCamera(lat,lng,bearing,18,45,0)
            }
        },
        async reRoutePath(){
            this.$store.commit('setState',{
                name: 'rerouting',
                value: true
            })
            let minDist = Infinity, closestNode = null, lat = this.$store.state.googleSourceLat, lon = this.$store.state.googleSourceLong
            for(const nodeId in this.$store.state.roadGraph.nodes){
                const node = this.$store.state.roadGraph.findNode(nodeId)
                let dist = this.haversineDistanceInMeters(lat, lon, node.data.lat, node.data.lon);
                if(dist < minDist){
                    minDist = dist
                    closestNode = nodeId
                }
            }
            this.$store.commit('setMultipleState',{
                mapRoute: {...(await this.applyRelativeAStar(closestNode, this.$store.getters.selectedChargingStation.closestNode))},
                rerouting: false,
            })
        }
    }
}