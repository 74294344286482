<template>
  <v-bottom-sheet v-model="$store.state.showBottomSheet" style="width: 100vw; height: 85vh;">
    <v-card
        class="pt-5 elevation-10"
        style="height: 100vh; width: 100vw; border-radius: 30px 30px 0 0 !important;"
    >
      <v-card-actions class="my-0 py-0 mx-3" style="min-height: 0">
        <p class="text-subtitle-1 font-weight-bold mb-4">Choose from the nearest stations</p>
        <v-spacer></v-spacer>
        <v-btn variant="plain" :color="$store.state.baseColor" @click="$store.commit('setState',{name: 'showBottomSheet', value: !$store.state.showBottomSheet})">
          <v-icon size="x-large">mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text class="pa-5 my-list-scroll pb-16">
        <StationList @stationSelected="navigatePath"/>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import StationList from './StationList.vue'
export default {
  name: "MobileBottomSheet",
  components: {
    StationList
  },
  methods: {
    navigatePath(id){
      this.$emit('navigationClicked', id);
    }
  }
}
</script>

<style scoped>

</style>