<template>
  <div>
    <v-app-bar v-if="!$store.state.showBottomSheet && $store.state.selectedStationId == null" :elevation="3" rounded>
      <template v-slot:append>
        <v-btn icon @click="loadData">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-app-bar-nav-icon @click="$store.commit('setState',{name: 'showBottomSheet', value: !$store.state.showBottomSheet})"></v-app-bar-nav-icon>
      </template>
      <v-app-bar-title>{{ $store.state.chargingStations.length }} stations available</v-app-bar-title>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "AppBar",
  props: {
    polylineRoute: Object
  },
  computed: {

  },
  methods: {
    loadData(){
      this.$emit("refresh")
    },
  }
}
</script>

<style scoped>

</style>