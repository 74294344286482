import { GraphNode } from './node';

export class Graph {
    constructor() {
        this.nodes = {};
    }

    addNode(id, data) {
        const newNode = new GraphNode(id, data);
        this.nodes[newNode.id] = newNode;
        return newNode;
    }

    removeNode(node) {
        delete(this.nodes[node.id]);
    }

    clearGraph(){
        this.nodes = {}
    }

    findNode(id) {
        return this.nodes[id];
    }

    addEdge(sourceId, targetId, data, directed = false) {
        let from = this.findNode(sourceId)
        if(from === undefined || from == null){
            throw new Error(`Node with ID: ${sourceId} not found in graph`)
        }
        let to = this.findNode(targetId)
        if(to === undefined || to == null){
            throw new Error(`Node with ID: ${targetId} not found in graph`)
        }
        this.nodes[from.id].addEdge(to, data)
        if(!directed){
            this.nodes[to.id].addEdge(from, data)
        }
    }

    getNodes() {
        return Object.keys(this.nodes).map(n=>{
            return this.nodes[n]
        })
    }
}
