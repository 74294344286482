import axios from "axios";
export default {
    methods: {
        async requestMapData(topLeft, bottomRight) {
            this.$store.commit("setState",{name: "loadingText", value: 'Collecting road networks data...'})
            let maxErr = false;
            const bbox = `${topLeft.lon},${bottomRight.lat},${bottomRight.lon},${topLeft.lat}`;
            try {
                const response = await axios.get(`https://api.openstreetmap.org/api/0.6/map?bbox=${bbox}`, {
                    headers: {
                        "Accept": "application/json"
                    }
                });
                this.$store.commit("setState",{name: "loadingText", value: 'Setting up road networks...'})

                if (Array.isArray(response.data.elements)) {
                    response.data.elements.forEach(e => {
                        if (e.type === 'node') {
                            this.processNode(e);
                        } else if (e.type === 'way'
                            && e.tags?.highway && this.$store.state.validHighwayClasses[e.tags.highway]
                            && !this.$store.state.inAccessibleHighway.includes(e.tags?.access)) {
                            this.processWay(e);
                        }
                    });
                }
            } catch (err) {
                if (err.response?.status === 400) {
                    maxErr = true;
                }else{
                    throw new Error("OSM api is busy, please try refreshing later.")
                }
            }

            if (maxErr) {
                const grids = this.createGrids(topLeft, bottomRight, 2, 1);
                await Promise.all(grids.map(g => this.requestMapData(g.topLeft, g.bottomRight)));
            }
        },
        async fetchRoadWorks(lon, lat, radius){
            try{
                this.$store.commit("setState",{name: "loadingText", value: 'Collecting road works updates...'})
                let response = await axios(`https://roads.maxximus.tech/works`, {
                    params: {
                        lat, lon, radius
                    },
                    headers: {
                        "Accept": "application/json"
                    }
                })
                let works = []
                response.data.works.forEach((w,i)=>{
                    works.push({
                        ...w,
                        showMarker: false
                    })
                })
                this.$store.commit("setState",{name: "roadWorks", value: works})
                return works;
            }catch(err){
                throw new Error("Error connecting to roadworks api, please try refreshing later.")
            }
        },
        async fetchTrafficSignals(lon, lat, radius){
            try{
                this.$store.commit("setState",{name: "loadingText", value: 'Collecting traffic signals data...'})
                let response = await axios(`https://roads.maxximus.tech/signals`, {
                    params: {
                        lat, lon, radius
                    },
                    headers: {
                        "Accept": "application/json"
                    }
                })
                this.$store.commit("setState",{name: "trafficSignals", value: response.data.signals})
                return response.data.signals
            }catch(err){
                throw new Error("Error connecting to traffic signals api, please try refreshing later.")
            }

        },
        async getEVCSAvailability(id){
            try{
                let headers = {
                    "Accept": "application/json"
                }
                if(this.$store.state.tomTomApi === process.env.VUE_APP_TOMTOM_API){
                    headers['Referer'] = "https://developer.tomtom.com/"
                }
                const url = `https://api.tomtom.com/search/2/chargingAvailability.json?chargingAvailability=${id}&key=${this.$store.state.tomTomApi}`
                let response = await axios(url,{
                    headers
                })
                if(response.status !== 200){
                    throw new Error(`TomTom EVCS Availability Error: ${response.status} - ${response.statusText}`)
                }
                return response.data.connectors
            }catch(err){
                if (err.response?.status === 403) {
                    this.$store.commit('changeTomTomApiKey')
                    return await this.getEVCSAvailability(id)
                }
                return []
            }
        },
        async getEVCSNearby(lon, lat, radius){
            try{
                let headers = {
                    "Accept": "application/json"
                }
                if(this.$store.state.tomTomApi === process.env.VUE_APP_TOMTOM_API){
                    headers['Referer'] = "https://developer.tomtom.com/"
                }
                this.$store.commit("setState",{name: "loadingText", value: 'Collecting EV charging stations data...'})
                const url = `https://api.tomtom.com/search/2/categorySearch/electric%20vehicle.json?lat=${lat}&lon=${lon}&radius=${radius * 1000}&view=Unified&relatedPois=off&key=${this.$store.state.tomTomApi}`
                let response = await axios(url,{
                    headers
                })
                let d = []
                for await (const c of response.data.results){
                    let cs = await this.getEVCSAvailability(c.id)
                    if(cs.filter(ca=>ca.availability.current.available > 0).length > 0){
                        d.push({
                            id: c.id,
                            distance: c.dist,
                            name: c.poi.name,
                            url: c.poi.url,
                            address: c.address,
                            position: c.position,
                            connectors: c.chargingPark.connectors,
                            connectorAvailability: cs,
                            closestNode: null,
                            distToNode: null,
                            showMarker: false
                        })
                    }
                }
                this.$store.commit("setState",{name: "chargingStations", value: d})
                return d
            }catch(err){
                if (err.response?.status === 403) {
                    this.$store.commit('changeTomTomApiKey')
                    return await this.getEVCSNearby(lon, lat, radius)
                }else{
                    throw new Error("Error connecting to charging stations api, please try refreshing later.")
                }
            }
        },
    }
}