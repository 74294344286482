export default {
    methods: {
        clearData(){
            this.$store.commit('setMultipleState',{
                mapRoute: {},
                showMap: false,
                selectedStationId: null,
                loading: true,
                firstLoad: true,
                osmNodes: {},
                osmWays: {},
                chargingStations: [],
                trafficSignals: [],
                roadWorks: []
            })
            this.$store.state.roadGraph.clearGraph()
        },
        haversineDistanceInMeters(lat1, lon1, lat2, lon2) {
            const R = 6371000; // Radius of the Earth in meters
            const dLat = (lat2 - lat1) * Math.PI / 180;
            const dLon = (lon2 - lon1) * Math.PI / 180;

            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            return R * c;
        },
        async optimiseRoute(useRelativeAStar = true){
            this.$store.commit('setState',{name: "loadingText", value: 'Please wait while we navigate your recharge journey'})
            let startPoint = this.findClosestNodeToPoint(this.$store.state.googleSourceLat,this.$store.state.googleSourceLong)
            let routes = [];
            for await(const cs of this.$store.state.chargingStations){
                if(useRelativeAStar){
                    routes.push({
                        chargingStationId: cs.id,
                        ...(await this.applyRelativeAStar(startPoint,cs.closestNode,cs.id))
                    })
                }else{
                    routes.push({
                        chargingStationId: cs.id,
                        ...(await this.applyAStar(startPoint,cs.closestNode,1,cs.id))
                    })
                }
            }
            return routes
        },
        findClosestNodeToPoint(lat, lon){
            let minDist = Infinity;
            let nodeId = null
            for(const node of this.$store.state.roadGraph.getNodes()){
                const dist = this.haversineDistanceInMeters(lat, lon, node.data.lat, node.data.lon);
                if (dist < minDist) {
                    minDist = dist
                    nodeId = node.id
                }
            }
            return nodeId;
        },
        constructPath(currentNode) {
            const path = [];
            let distance = 0;
            let current = currentNode;

            while (current) {
                distance += current.dist;
                path.push(current.id);
                current = current.prev;
            }

            return {
                distance,
                time: currentNode.g,
                path: path.reverse()
            };
        },
        async applyRelativeAStar(source, target, cs_id){
            let routes = []
            let relativeFactor = 0.5
            while(relativeFactor <= 1){
                routes.push({
                    ...await this.applyAStar(source,target, relativeFactor),
                    relativeFactor
                })
                relativeFactor += 0.1
            }
            routes.sort((a, b) => a.time - b.time);
            if(cs_id != null){
                let index = this.$store.state.chargingStations.findIndex(s=>s.id===cs_id)
                if(index > -1){
                    this.$store.commit('setChargingStationRoute',{index, route: routes[0]})
                }
            }
            return routes[0]
        },
        async applyAStar(source, target, relative_factor = 1, cs_id = null) {
            let route;
            const queue = [];
            const closedList = {};
            const sourceNode = this.$store.state.roadGraph.findNode(source);
            const targetNode = this.$store.state.roadGraph.findNode(target);

            if(!sourceNode || !targetNode){
                route = undefined;
            }else{
                queue.push({
                    id: source,
                    lat: sourceNode.data.lat,
                    lng: sourceNode.data.lon,
                    g: 0,
                    f: this.haversineDistanceInMeters(
                        sourceNode.data.lat,
                        sourceNode.data.lon,
                        targetNode.data.lat,
                        targetNode.data.lon
                    ) / 30,
                    prev: null,
                    dist: 0,
                    time: 0
                });
                while (queue.length > 0) {
                    queue.sort((a, b) => a.f - b.f);
                    const currentNode = queue.shift();
                    const curNode = this.$store.state.roadGraph.findNode(currentNode.id)
                    if (+currentNode.id === +target) {
                        const path = [];
                        let distance = 0
                        let current = currentNode;
                        while (current) {
                            distance += current.dist
                            path.push({nodeId: current.id, time: current.time, dist: current.dist, lat: current.lat, lng: current.lng});
                            current = current.prev;
                        }
                        route = {
                            distance,
                            time: currentNode.g,
                            path: path.reverse()
                        }
                        break;
                    }
                    else{
                        closedList[currentNode.id] = currentNode.id;
                        const currentEdges = curNode.edges;
                        for (const edgeId in currentEdges) {
                            let edgeNode = this.$store.state.roadGraph.findNode(edgeId)
                            let edge = currentEdges[edgeId]
                            if (closedList[edgeId] !== undefined) {
                                continue;
                            }
                            let h = this.haversineDistanceInMeters(
                                edgeNode.data.lat,
                                edgeNode.data.lon,
                                targetNode.data.lat,
                                targetNode.data.lon
                            ) / this.$store.state.validHighwayClasses[edge['highway']]['maxspeed']
                            let g = currentNode.g + edge['time']
                            if(curNode['data']['streetName'] && edgeNode['data']['streetName'] && (curNode['data']['streetName'] === edgeNode['data']['streetName'])){
                                h *= relative_factor
                            }
                            let edgeAsNode = {
                                id: edgeId,
                                g,
                                f: g + h,
                                prev: currentNode,
                                dist: currentEdges[edgeId]['dist'],
                                time: edge['time'],
                                lat: edgeNode.data.lat,
                                lng: edgeNode.data.lon
                            }
                            const existingNode = queue.find(n => n.id == edgeAsNode.id);

                            if (!existingNode || edgeAsNode.g < existingNode.g) {
                                if (existingNode) {
                                    queue.splice(queue.indexOf(existingNode), 1);
                                }
                                queue.push(edgeAsNode);
                            }
                        }
                    }
                }
            }
            if(cs_id != null){
                let index = this.$store.state.chargingStations.findIndex(s=>s.id===cs_id)
                if(index > -1){
                    this.$store.commit('setChargingStationRoute',{index, route})
                }
            }
            return route
        },
        mphToMps(mph) {
            const metersPerMile = 1609.34;
            const secondsPerHour = 3600;
            return (mph * metersPerMile) / secondsPerHour;
        },
        processNode(node) {
            if (!this.$store.state.osmNodes[node.id]) {
                this.$store.commit('setOSMNode',{
                    id: node.id,
                    lat: node.lat,
                    lon: node.lon,
                    tags: node.tags
                })
            }
        },
        processWay(way) {
            if (!this.$store.state.osmWays[way.id]) {
                const nodes = way.nodes.map(nodeId => {
                    let node = this.$store.state.osmNodes[nodeId];
                    this.addNodeToRoadGraph(node, way.tags.name);
                    return node.id;
                });
                this.$store.commit('setOSMWay',{ id: way.id, nodes, tags: way.tags })
                this.addEdgesToRoadGraph(nodes, way);
            }
        },
        addNodeToRoadGraph(node, streetName) {
            if (!this.$store.state.roadGraph.nodes[node.id]) {
                let traffic_type = 'usual', traffic_signal = false
                let work = this.$store.state.roadWorks.find(w=>w.closestNode==node.id)
                let signal = this.$store.state.trafficSignals.find(s=>s.closestNode==node.id)
                if(work != null) {
                    traffic_type = work.object_data.traffic_management_type_ref
                }
                if(signal != null) {
                    traffic_signal = true
                }
                this.$store.state.chargingStations.forEach((cs, i)=>{
                    let dist = this.haversineDistanceInMeters(cs.position.lat, cs.position.lon, node.lat, node.lon);
                    if(cs.distToNode == null || dist < cs.distToNode){
                        this.$store.commit("setStations",{
                            ...cs,
                            distToNode: dist,
                            closestNode: node.id
                        })
                    }
                })
                this.$store.state.roadGraph.addNode(node.id, {
                    id: node.id,
                    lat: node.lat,
                    lon: node.lon,
                    tags: node.tags,
                    traffic_type,
                    traffic_signal,
                    streetName
                });
            }
        },
        divideLineIntoChunks(startPoint, endPoint, chunkCount) {
            const points = [];
            const xStep = (endPoint.x - startPoint.x) / chunkCount;
            const yStep = (endPoint.y - startPoint.y) / chunkCount;

            for (let i = 0; i <= chunkCount; i++) {
                const x = startPoint.x + i * xStep;
                const y = startPoint.y + i * yStep;
                points.push({ x, y });
            }
            return points;
        },
        addEdgesToRoadGraph(nodes, way) {
            for (let i = 0; i < nodes.length - 1; i++) {
                const node1 = this.$store.state.roadGraph.findNode(nodes[i]);
                const node2 = this.$store.state.roadGraph.findNode(nodes[i + 1]);
                const dist = this.haversineDistanceInMeters(node1.data.lat, node1.data.lon, node2.data.lat, node2.data.lon);
                const speed = this.getSpeed(way, node1);
                const highway = node1.tags?.highway || way.tags.highway;
                let time = Math.ceil(dist / this.mphToMps(speed)) + (highway === 'traffic_signals' || node1.traffic_signal ? Math.ceil(this.$store.state.trafficWaitTime * this.$store.state.validHighwayClasses[highway]['traffic_factor']) : 0)
                time += node1.traffic_type === 'give_and_take' ? Math.ceil(this.$store.state.giveAndTakeWaitTime * this.$store.state.validHighwayClasses[highway]['give_and_take_factor']) : 0
                if(node1.traffic_type !== 'road_closure' && node1.traffic_type !== 'lane_closure'){
                    this.$store.state.roadGraph.addEdge(nodes[i], nodes[i + 1], {
                        dist,
                        time,
                        speed,
                        streetName: node1.streetName,
                        undirected: way.tags.oneway !== 'yes',
                        highway: highway
                    }, (way.tags.oneway === 'yes' || way.tags.junction === 'roundabout'));
                }
            }
        },
        getSpeed(way, node) {
            return parseInt(node.tags?.maxspeed || way.tags.maxspeed || this.$store.state.validHighwayClasses[way.tags.highway].maxspeed) * this.$store.state.userSpeedFactor;
        },
    }
}