<template>
  <v-main style="padding: 0 !important; margin: 0 !important;">
    <v-sheet style="height: 100vh; width: 100vw; padding: 0 !important; margin: 0 !important;" class="d-flex align-center">
      <div class="mx-auto text-center">
        <div class="mx-auto">
          <v-icon style="font-size: 80px;" :color="$store.state.baseColor">mdi-delete-empty</v-icon>
        </div>
        <div class="text-subtitle-1 font-weight-bold mb-2" style="line-height: 1.3">Oops! We could not find any available charging station near you.</div>
        <v-btn variant="flat" class="mx-1 text-white" :color="$store.state.baseColor" theme="dark" @click="retry">Retry</v-btn>
      </div>
    </v-sheet>
  </v-main>
</template>

<script>
export default {
  name: "NoStationsFound",
  methods: {
    retry() {
      this.$emit('retry');
    }
  }
};
</script>
